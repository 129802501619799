import { ResultPersonObject } from "@/interfaces/ResultPersonObject";
import { reactive } from "vue";
import { indexedObject } from "@/interfaces/indexedObject";
import { indexedArrayObject } from "@/interfaces/indexedArrayObject";

export const store: {
  SAP: any[];
  SAPLohnart: string[];
  Data: any[];
  UniquePersonNumbers: number[];
  Month: string;
  SelectedLohnarten: string[];
  ResultPerson: ResultPersonObject[];
  ResultKostenstelle: number[];
  lohnCheckerATP: string[];
  lohnCheckerWP: string[];
  lohnCheckerQuali: string[];
  sozialCheckerATP: string[];
  sozialCheckerWP: string[];
  sozialCheckerQuali: string[];
  SLATP: indexedArrayObject;
  SLWP: indexedArrayObject;
  SLQUALI: indexedArrayObject;
  filesdone: boolean;
  calculationsdone: boolean;
  excelFiles: any[];
  sapFile: any;
  sozialabgabenKSTObject: indexedObject;
  loggedin: boolean;
  zeroPerson: any[];
} = reactive({
  SAP: [], // SAP Data extracted from SAP file
  SAPLohnart: [], // Lohnarten extracted from SAP file for selection
  Data: [], // Data extracted from the Lehrstühle files
  UniquePersonNumbers: [], // unique persons found in SAP file to extract rows from files
  Month: "Undefined", // the month that is calculated
  SelectedLohnarten: [], // The Lohnarten that are from SAPLohnart and are summed up to calculate the Lohn
  ResultPerson: [], // The Results from the calculations but on a per-person basis
  ResultKostenstelle: [], // The calculated Results but on a per-kostenstelle-basis
  zeroPerson: [], // they wanted to put in persons that do not a have a personnumber yet as a 0. So those will be multiple/different persons but all with the same number
  lohnCheckerATP: [
    //"1000",
    //"/480",
    //"1167",
    //"1168",
    //"1169",
    //"2000",
    //"1280",
    //"1429",
    //"1435",
    //"1471",
  ], // We are storing the lohnarten which were used for calculation so we can show in the results/put it into the report in case of there is a mistake for them to control if they put in the correct lohnarten
  lohnCheckerWP: [
    //"1425",
    //"1001",
    //"1000",
    //"/480",
    //"1001",
    //"1004",
    //"1430",
    //"1428",
    //"1480",
    //"1470",
    //"1471",
  ],
  lohnCheckerQuali: [],
  sozialCheckerATP: [
    //"/412",
    //"/429",
    //"/40A",
    //"/452",
    //"/462",
    //"/43C",
    //"/439",
    //"/410",
    //"9980",
  ],
  sozialCheckerWP: [
    //"/412",
    //"/42C",
    //"/429",
    //"/40A",
    //"/452",
    //"/43C",
    //"/439",
    //"/410",
    //"/412",
    //"/429",
    //"/40A",
    //"/462",
    //"9952",
    //"9962",
    //"/439",
    //"/43C",
    //"1479",
    //"1469",
    //"9980",
  ],
  sozialCheckerQuali: [],
  SLATP: {},
  SLWP: {},
  SLQUALI: {},
  filesdone: false,
  calculationsdone: false,
  excelFiles: [],
  sapFile: null,
  sozialabgabenKSTObject: {},
  loggedin: false,
});
