import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
// PrimeVue
import PrimeVue from "primevue/config";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import FileUpload from "primevue/fileupload";
import Dropdown from "primevue/dropdown";
import ConfirmDialog from "primevue/confirmdialog";

import ConfirmationService from "primevue/confirmationservice";
import ToastService from "primevue/toastservice";
import Toast from "primevue/toast";
import Dialog from "primevue/dialog";
import Password from "primevue/password";
import Editor from "primevue/editor";
import Divider from "primevue/divider";

// Data Table Component
import DataTable from "primevue/datatable";
import Column from "primevue/column";
// Calendar Component for trying out
import Calendar from "primevue/calendar";
// Themes
//import "primevue/resources/themes/lara-dark-teal/theme.css";
import "primevue/resources/themes/lara-light-teal/theme.css";
//import "primevue/resources/themes/bootstrap4-light-blue/theme.css"
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

const app = createApp(App);
app.use(PrimeVue);
app.use(router);
app.use(ConfirmationService);
app.use(ToastService);
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("Button", Button);
app.component("InputText", InputText);
app.component("FileUpload", FileUpload);
app.component("Dropdown", Dropdown);
app.component("ConfirmDialog", ConfirmDialog);
app.component("Toast", Toast);
app.component("Dialog", Dialog);
app.component("Password", Password);
app.component("InputText", InputText);
app.component("Editor", Editor);
app.component("Divider", Divider);
app.mount("#app");

//createApp(App).use(router, PrimeVue).component('Dialog', Dialog).mount("#app");
