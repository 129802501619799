<template>
  <div>
    <h1>RWI Lohnkontrolle</h1>
    <p>
      Internes Tool zur Unterstützung der RWI Personalabteilung: Lohn-Vergleich
      einer SAP-Dump Datei mit einer oder mehreren manuellen Lehrstühlen-Excel
      Dateien.
    </p>
    <Button
      label="Dokumentation"
      class="p-button-text"
      @click.prevent="gotodocumentation()"
    />
    <br /><br /><br />
    <Button v-if="loggedin" @click="start">Start</Button>
    <Button v-if="!loggedin" @click="login">Login</Button>
    <Dialog v-model:visible="display">
      <template #header>
        <h3>Login</h3>
      </template>
      <p>Username:</p>
      <InputText v-model="username" type="text" />
      <p>Password:</p>
      <Password v-model="password" :feedback="false"> </Password>
      <p style="color:red;">{{message1}}</p>
      <p style="color:red;">{{message2}}</p>
      <template #footer>
        <Button @click="loginRequest">Login</Button>
      </template>
    </Dialog>
  </div>
</template>

<script>
import router from "@/router";
import { store } from "../store/store";
export default {
  data() {
    return {
      display: false,
      username: "",
      password: "",
      message1: "",
      message2: "",
    };
  },
  computed: {
    loggedin() {
      return store.loggedin;
    },
  },
  mounted() {
    fetch("/api/auth/logged-in")
      .then(async (response) => {
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        store.loggedin = true;
      })
      .catch((error) => {
        store.loggedin = false;
      });
  },
  methods: {
    start() {
      router.push("Files");
    },
    gotodocumentation() {
      router.push("Documentation");
    },
    login() {
      this.display = true;
    },
    loginRequest() {
      // POST request using fetch with error handling
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: this.username, password: this.password }),
      };
      fetch("/api/auth/login", requestOptions)
        .then(async (response) => {
          const data = await response.json();

          // check for error response
          if (!response.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
          }
          store.loggedin = true;
          router.push("Files");
        })
        .catch((error) => {
          this.errorMessage = error;
          this.message1 = "Login Failed"
          this.message2 = "Check your Username and Password"
          console.error("There was an error!", error);
        });
    },
  },
};
</script>

<style>
a {
  margin: 0;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: var(--surface-b);
  font-family: var(--font-family);
  font-weight: 400;
  color: var(--text-color);
  font-size: 12px;
}

a:visited {
  margin: 0;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: var(--surface-b);
  font-family: var(--font-family);
  font-weight: 400;
  color: var(--text-color);
  font-size: 12px;
}
</style>
